*, :after, :before {
  box-sizing: border-box;
  border: 0 solid #f8f8f8;
}

:after, :before {
  --tw-content: "";
}

html {
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  height: 0;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, pre, samp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  border-collapse: collapse;
  border-color: inherit;
  text-indent: 0;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

[type="button"], [type="reset"], [type="submit"], button {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dd, dl, figure, h1, h2, h3, h4, h5, h6, hr, p, pre, fieldset {
  margin: 0;
}

fieldset, legend {
  padding: 0;
}

menu, ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  color: #dfdddc;
  opacity: 1;
}

[role="button"], button {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

audio, canvas, embed, iframe, img, object, svg, video {
  vertical-align: middle;
  display: block;
}

img, video {
  height: auto;
  max-width: 100%;
}

*, :after, :before, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

html {
  --tw-text-opacity: 1;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: rgb(48 60 61 / var(--tw-text-opacity));
  height: 100%;
  font-family: Sofia-pro, sans-serif;
  font-size: clamp(16px, 2vw, 18px);
  line-height: 1.5;
  overflow-y: scroll;
}

a {
  text-decoration-line: none;
}

.text-h1, .text-h2, .text-h3, .text-h4, h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  line-height: 1;
}

h1 {
  font-size: 2.4rem;
}

h2 {
  font-size: 1.7rem;
}

h3 {
  font-size: 1.35rem;
}

h4, h5, h6 {
  font-size: 1rem;
}

label {
  font-weight: 700;
}

input {
  accent-color: #87e9dd;
}

tr {
  border-bottom: 1px solid #dfdddc;
}

td, th {
  padding-block: .5rem;
}

td:not(:last-child), th:not(:last-child) {
  padding-right: .5rem;
}

.container-lg {
  max-width: 1620px;
}

.container-md {
  max-width: 1280px;
}

.container-sm {
  max-width: 1024px;
}

.btn {
  --tw-text-opacity: 1;
  --tw-bg-opacity: 1;
  background-color: rgb(76 193 184 / var(--tw-bg-opacity));
  color: rgb(255 255 255 / var(--tw-text-opacity));
  text-align: center;
  border-radius: .25rem;
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  text-decoration-line: none;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: inline-block;
}

.btn:not(:disabled):hover {
  --tw-bg-opacity: 1;
  background-color: rgb(135 233 221 / var(--tw-bg-opacity));
}

.btn:disabled {
  color: rgb(199 193 188 / var(--tw-text-opacity));
  cursor: not-allowed;
}

.btn-gray, .btn:disabled {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgb(242 242 242 / var(--tw-bg-opacity));
}

.btn-gray {
  color: rgb(48 60 61 / var(--tw-text-opacity));
}

.btn-gray:not(:disabled):hover {
  --tw-bg-opacity: 1;
  background-color: rgb(223 221 220 / var(--tw-bg-opacity));
}

.btn-white {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  color: rgb(76 193 184 / var(--tw-text-opacity));
}

.btn-white:not(:disabled):hover {
  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity));
}

.btn-large {
  min-height: 60px;
  width: 100%;
  font-size: 20px;
  line-height: 1.8;
}

@media (min-width: 768px) {
  .btn-large {
    max-width: 500px;
    min-width: 250px;
  }
}

.btn-small {
  padding: .25rem 1rem;
  font-size: .85rem;
}

.btn-icon {
  border-radius: 50%;
  padding: .5rem;
}

.btn-combo {
  justify-content: center;
  align-items: center;
  display: flex;
}

.btn-combo .icon {
  margin-right: .5rem;
}

.input {
  --tw-border-opacity: 1;
  border-color: rgb(199 193 188 / var(--tw-border-opacity));
  max-width: 20rem;
  width: 100%;
  background-color: #0000;
  border-width: 1px;
  border-radius: .375rem;
  padding: .5rem;
}

.input-white {
  border-color: #fff;
}

.input-white:focus, .input-white:focus-visible {
  outline: 2px solid #fff;
}

input::-webkit-input-placeholder {
  --tw-text-opacity: 1;
  color: rgb(48 60 61 / var(--tw-text-opacity));
  opacity: .8;
}

input::-moz-placeholder {
  --tw-text-opacity: 1;
  color: rgb(48 60 61 / var(--tw-text-opacity));
  opacity: .8;
}

input:-ms-input-placeholder {
  --tw-text-opacity: 1;
  color: rgb(48 60 61 / var(--tw-text-opacity));
  opacity: .8;
}

input:-moz-placeholder {
  --tw-text-opacity: 1;
  color: rgb(48 60 61 / var(--tw-text-opacity));
  opacity: .8;
}

.input-white::-webkit-input-placeholder {
  color: #fff;
  opacity: .8;
}

.input-white::-moz-placeholder {
  color: #fff;
  opacity: .8;
}

.input-white:-ms-input-placeholder {
  color: #fff;
  opacity: .8;
}

.input-white:-moz-placeholder {
  color: #fff;
  opacity: .8;
}

input::-webkit-inner-spin-button, input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.navigation {
  padding-bottom: .5rem;
}

.navigation a {
  max-width: 20rem;
  border-color: #0000;
  border-left-width: 4px;
  border-radius: .375rem;
  align-items: center;
  gap: .75rem;
  padding: .5rem;
  display: flex;
}

.navigation .is-active {
  background-color: rgb(223 221 220 / var(--tw-bg-opacity));
}

.navigation .is-active, .textbubble {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  border-color: rgb(76 193 184 / var(--tw-border-opacity));
}

.textbubble {
  background-color: rgb(238 252 250 / var(--tw-bg-opacity));
  border-width: 1px;
  border-radius: .375rem;
  justify-content: space-between;
  align-items: flex-start;
  gap: .75rem;
  padding: 1rem;
  display: flex;
}

.notification:before {
  content: "";
  height: 2rem;
  width: 2rem;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%234CC1B8' class='w-6 h-6'%3E%3Cpath fill-rule='evenodd' d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25z' clip-rule='evenodd'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  display: block;
}

.notification--progress {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  background-color: rgb(238 252 250 / var(--tw-bg-opacity));
  border-color: rgb(76 193 184 / var(--tw-border-opacity));
  border-width: 1px;
  border-radius: .375rem;
  justify-content: space-between;
  align-items: flex-start;
  gap: .75rem;
  padding: 1rem;
  display: flex;
}

.notification--progress:before {
  content: "";
  height: 2rem;
  width: 2rem;
  background-image: url("spinner32.a9128845.svg");
  background-repeat: no-repeat;
  display: block;
}

.notification--error {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.notification--error:before {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23ef4444' class='w-6 h-6'%3E%3Cpath fill-rule='evenodd' d='M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72z' clip-rule='evenodd'/%3E%3C/svg%3E");
}

.notification--warning {
  background-color: #ffd7be;
  border-color: #ff6707;
}

.notification--warning:before {
  content: "";
  height: 2rem;
  width: 2rem;
  background-image: url("warning32.46f982fb.png");
  background-repeat: no-repeat;
  display: block;
}

.notification__title {
  margin-bottom: .25rem;
  font-size: 1rem;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  white-space: nowrap;
  width: 1px;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.visible {
  visibility: visible;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.float-right {
  float: right;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-full {
  height: 100%;
}

.h-6 {
  height: 1.5rem;
}

.w-full {
  width: 100%;
}

.w-6 {
  width: 1.5rem;
}

.w-4 {
  width: 1rem;
}

.w-28 {
  width: 7rem;
}

.w-16 {
  width: 4rem;
}

.max-w-2xs {
  max-width: 12em;
}

.max-w-sm {
  max-width: 24rem;
}

.flex-1 {
  flex: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.grow {
  flex-grow: 1;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.resize {
  resize: both;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.gap-2 {
  gap: .5rem;
}

.gap-4 {
  gap: 1rem;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-md {
  border-radius: .375rem;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(242 242 242 / var(--tw-border-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(242 242 242 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-cyan-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(212 247 243 / var(--tw-bg-opacity));
}

.bg-cyan-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(76 193 184 / var(--tw-bg-opacity));
}

.p-4 {
  padding: 1rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pl-8 {
  padding-left: 2rem;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-xl {
  font-size: 1.7rem;
}

.text-lg {
  font-size: 1.35rem;
}

.text-sm {
  font-size: .85rem;
}

.text-base {
  font-size: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.uppercase {
  text-transform: uppercase;
}

.text-cyan-500 {
  --tw-text-opacity: 1;
  color: rgb(76 193 184 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.no-underline {
  text-decoration-line: none;
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.blur {
  --tw-blur: blur(8px);
}

.blur, .filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.disabled {
  color: #a0a0a0;
  pointer-events: none;
}

.linkButton, a.externalLink {
  text-decoration-line: underline;
}

.linkButton {
  cursor: pointer;
}

.linkButton:hover {
  color: #689397;
}

.mobileDownloadIcon {
  width: 1.3rem;
}

.tableIcon {
  height: 1.5rem;
  width: 1.5rem;
  background-repeat: no-repeat;
  background-size: 1.2rem;
}

.mobileRemoveIcon {
  background-image: url("trash.f6836490.png");
}

.tableSpinnerIcon {
  background-image: url("spinner32.a9128845.svg");
}

.tableModelIcon {
  background-image: url("3dmode_icon.e9d75551.svg");
}

.tableImageIcon {
  background-image: url("Icon_Image.ab1c5037.svg");
}

.tableACCIcon {
  background-image: url("Icon_ImportACC.b9e88392.svg");
}

.tableRevitIcon {
  background-image: url("Icon_ImportRevit.76b3961f.svg");
}

.tableRhinoIcon {
  background-image: url("Icon_ImportRhino.309d62b2.svg");
}

.tableSketchupIcon {
  background-image: url("Icon_ImportSketchup.3aaa8bed.svg");
}

.tableUnityIcon {
  background-image: url("Icon_ImportUnity.8accf221.svg");
}

.tableErrorIcon {
  background-image: url("error32.90a68c64.png");
}

.clickableSpan {
  cursor: pointer;
}

.clickableSpan:hover {
  color: #888;
}

.clickableText {
  text-decoration: underline;
}

.copyIcon {
  height: 24px;
  width: 24px;
  background-image: url("copy24.8f6c6125.png");
  margin-left: 4pt;
  margin-right: 4pt;
}

.copyElement {
  float: left;
}

.inputOverride {
  max-width: 100%;
}

.mediumButton {
  max-width: 50%;
}

.autodeskSigninButton {
  border: 1px solid #ccc;
  border-radius: 6px;
  margin: 1em;
  padding: 8pt 14pt 10pt;
  line-height: 1.33333;
}

.autodeskSigninButton:hover {
  background-color: #e6e6e6;
  border-color: #adadad;
}

.autodeskSigninLabel {
  text-align: right;
  width: 11em;
  background-image: url("Autodesk_Full.f3e8b18c.svg");
  background-position: 0 .3em;
  background-repeat: no-repeat;
  background-size: 68%;
}

.greytext {
  color: #a09c99;
}

#ACCLoginSection {
  text-align: center;
  margin-top: 2em;
}

#treediv {
  font-size: 9pt;
  overflow-x: auto;
  overflow-y: scroll;
}

.treedivWideScreen {
  flex: 0 0 40%;
}

.treedivNarrowScreen {
  flex: 50%;
}

#forgeViewerInner {
  aspect-ratio: 1 / 1;
  width: 100%;
  position: relative;
}

.forgeViewerWideScreen {
  flex: 0 0 58%;
}

.forgeViewerNarrowScreen {
  flex: 50%;
}

.accDocumentPart {
  box-sizing: border-box;
  position: relative;
}

.middleContainer {
  max-width: 1600px;
}

#accDocuments {
  width: 100%;
  flex-direction: row;
  display: flex;
}

#shareACCModelWithGroupButton {
  margin-left: auto;
}

@media (min-width: 640px) {
  .sm\:max-w-none {
    max-width: none;
  }
}

@media (min-width: 768px) {
  .md\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .md\:my-8 {
    margin-bottom: 2rem;
  }

  .md\:mt-8, .md\:my-8 {
    margin-top: 2rem;
  }

  .md\:inline {
    display: inline;
  }

  .md\:hidden {
    display: none;
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:p-12 {
    padding: 3rem;
  }

  .md\:p-10 {
    padding: 2.5rem;
  }

  .md\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

/*# sourceMappingURL=index.492e233d.css.map */
